import { EventEmitter } from '@angular/core';

export class AgGridDataSource {
  /**
   * Notifica mudanças na lista.
   *
   * @type {EventEmitter}
   */
  valueChanges: EventEmitter<any> = new EventEmitter();

  /**
   * Representa os dados gravados na coleção.
   *
   * @type {Array}
   */
  private _storage: Array<any> = [];

  get storage(): Array<any> {
    return this._storage;
  }

  set storage(data: Array<any>) {
    this._storage = data;
  }

  constructor() {}

  /**
   * Adiciona um novo elemento à coleção.
   *
   * @param item
   */
  add(item: any): void {
    this.storage = this.push(item);
    this.valueChanges.emit(this.storage);
  }

  update(oldItem: any, item): void {
    const storage = this.copyStorage();
    storage[storage.indexOf(oldItem)] = item;

    this.storage = storage;
    this.valueChanges.emit(this.storage);
  }

  updateIndex(index: number, item: any): void {
    const storage = this.copyStorage();
    storage[index] = item;

    this.storage = storage;
    this.valueChanges.emit(this.storage);
  }

  remove(item: any): void {
    this.storage = this.splice(item);
    this.valueChanges.emit(this.storage);
  }

  removeIndex(index: number): void {
    const storage = this.copyStorage();
    storage.splice(index, 1);

    this.storage = storage;
    this.valueChanges.emit(this.storage);
  }

  reset(): void {
    this.storage = [];
    this.valueChanges.emit(this.storage);
  }

  private copyStorage(): Array<any> {
    return [...this.storage];
  }

  private push(item: any): Array<any> {
    const storage = this.copyStorage();
    storage.push(item);

    return storage;
  }

  private splice(item: any) {
    const storage = this.copyStorage();
    storage.splice(storage.indexOf(item), 1);

    return storage;
  }
}
