import { CurrencyPipe, DecimalPipe, PercentPipe, DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { CnpjPipe } from '@shared/pipes/cnpj.pipe';
import { CpfPipe } from '@shared/pipes/cpf.pipe';
/**
 * @whatItDoes Disponibiliza funções para serem utilizadas como renderer no ag-grid.
 *
 * @description Esta classe possui uma coleção de funções que são utilizadas
 * no ag-grid para exibir valores de forma customizada, como por exemplo
 * valores decimais ou valores monetários.
 *
 * @class AgGridRenderer
 */
@Injectable()
export class AgGridRenderer {
  constructor(
    private _decimal: DecimalPipe,
    private _currency: CurrencyPipe,
    private _percentPipe: PercentPipe,
    private _datePipe: DatePipe
  ) {}

  /**
   * Retorna valores decimais no padrão brasileiro.
   *
   * @example
   *
   * {
   *   headerName: 'Valor unitário',
   *   field: 'vlUnitario',
   *   cellClass: 'col-grid-2',
   *   headerClass: 'col-grid-2',
   *   cellRenderer: (params) => this.agGridRenderer.decimal(params)
   * },
   *
   * @param params Parâmetros fornecidos pela célula do ag-grid.
   * @param digits Pattern para formatação da célula.
   * @returns {string} Valor a ser exibido na célula.
   */
  decimal(params: any, digits = '.2-2'): string {
    return this._decimal.transform(params.value, digits, 'pt-br');
  }

  /**
   * Retorna valores monetários no padrão brasileiro.
   *
   * @example
   *
   * {
   *   headerName: 'Valor total',
   *   field: 'vlTotal',
   *   cellClass: 'col-grid-2',
   *   headerClass: 'col-grid-2',
   *  cellRenderer: (params) => this.agGridRenderer.currency(params)
   * },
   *
   * @param params Parâmetros fornecidos pela célula do ag-grid.
   * @returns {string} Valor a ser exibido na célula.
   */
  currency(params: any): string {
    return this._currency.transform(params.value, 'R$ ', 'symbol', '1.2-2', 'pt-br');
  }

  /**
   * Retorna percentuais no padrão brasileiro.
   *
   * @example
   *
   * {
   *   headerName: 'Percentual',
   *   field: 'vlPercentual',
   *   cellClass: 'col-grid-2',
   *   headerClass: 'col-grid-2',
   *   cellRenderer: (params) => this.agGridRenderer.percent(params)
   * },
   *
   * @param params Parâmetros fornecidos pela célula do ag-grid.
   * @param digits Pattern para formatação da célula.
   * @returns {string} Valor a ser exibido na célula.
   */
  percent(params: any, digits = '0.2-4'): string {
    return this._percentPipe.transform(params.value / 100, digits, 'pt-br');
  }

  /**
   * Retorna a data no padrão brasileiro.
   *
   * @example
   *
   * {
   *   headerName: 'Data inicial',
   *   field: 'dtInicio',
   *   cellClass: 'col-grid-2',
   *   headerClass: 'col-grid-2',
   *   cellRenderer: (params) => this.agGridRenderer.date(params)
   * },
   *
   * @param params Parâmetros fornecidos pela célula do ag-grid.
   * @returns {string} Valor a ser exibido na célula.
   */
  date(params: any): string {
    return this._datePipe.transform(params.value, 'dd/MM/yyyy');
  }

  /*
   * Retorna a data e hora no padrão brasileiro (dd/MM/yyyy HH:mm:ss).
   * @param params Parâmetros fornecidos pela célula do ag-grid.
   * @returns {string} Valor a ser exibido na célula.
   */
  dateHour(params: any): string {
    return this._datePipe.transform(params.value, 'dd/MM/yyyy HH:mm:ss');
  }

  /*
   * Retorna a data e hora no padrão brasileiro (dd/MM/yyyy HH:mm:).
   * @param params Parâmetros fornecidos pela célula do ag-grid.
   * @returns {string} Valor a ser exibido na célula.
   */
  dateHourWithoutSeconds(params: any): string {
    return this._datePipe.transform(params.value, 'dd/MM/yyyy HH:mm');
  }

  /**
   * Formata o valor passado com a máscara de cnpj.
   */
  cnpj(params: any): string {
    return new CnpjPipe().transform(params.value);
  }

  /**
   * Formata o valor passado com a máscara de cpf.
   */
  cpf(params: any): string {
    return new CpfPipe().transform(params.value);
  }

  /**
   * Formata true/false para Sim/Não
   * @param params
   */
  boolean(params: any): string {
    const trueFalse = { true: 'Sim', false: 'Não', 1: 'Sim', 0: 'Não' };
    return trueFalse[params.value];
  }

  /**
   * Formata hora.
   * @param params
   */
  hour(params: any): string {
    return this._datePipe.transform(params.value, 'hh:mm');
  }
}
