import { UntypedFormGroup } from '@angular/forms';

export abstract class FormManager {

  static markFormAsTouched(form: UntypedFormGroup): void {

    Object.keys(form.controls).map((objectKey) => {
      const formControl = form.controls[objectKey];
      formControl.markAsTouched();

      // implementação utilizada para disparar o statusChanges
      if (!formControl.valid) {
        formControl.updateValueAndValidity();
      }
    });

  }
}
