import { Injectable } from '@angular/core';

import { isNumber } from 'is-what';

/**
 * @whatItDoes Fornece um objeto capaz de interagir com uma coleção de dados [array]
 * assumindo o comportamento de lista.
 *
 * @description
 * A classe a seguir desempenha as operações básicas de uma lista. A coleção operada pela lista é um array e pode
 * receber qualquer tipo de dado seja primitivo ou objeto. Somente as operações next(), prev(), start(), end() e
 * moveTo() alteram a posição do iterator do objeto.
 *
 * A coleção é iniciada como objeto vazio e o iterator possui sua posição  inicial definida como 0.
 *
 * @author Ronildo dos Santos <ronildo.carneiro@kepha.com.br>
 *
 * @stable
 */
@Injectable()
export class ArrayList<T> {
  /**
   * Representa os dados gravados nesta coleção.
   *
   * @type {Array}
   */
  private storage: T[] = [];

  /**
   * Representa o ponteiro de iteração da coleção.
   *
   * @type {number}
   */
  private iterator = 0;

  /**
   * Retorna o array de dados desta coleção.
   *
   * @returns {any[]}
   */
  toArray(): Array<T> {
    return this.storage;
  }

  /**
   * Adiciona um novo elemento à coleção.
   *
   * @param data
   */
  add(data: T | T[]): void {
    if (Array.isArray(data)) {
      data.forEach(item => {
        this.storage.push(item);
      });
    } else {
      this.storage.push(data);
    }
  }

  clear(): void {
    this.storage = [];
    this.iterator = 0;
  }

  /**
   * Remove um elemento da coleção pelo seu numero de indexação.
   *
   * @param {number} index
   */
  remove(index: number): void {
    this.storage.splice(index, 1);
  }

  /**
   * Remove um elemento da coleção pela url.
   *
   * @param {number} index
   */
  removeByUrl(url: string): void {
    let indextab = null;
    this.storage.forEach((tab, index) => {
      const aux: any = tab;
      if (aux.dsUrl === url) {
        indextab = index;
      }
    });

    if (indextab) {
      this.storage.splice(indextab, 1);
    }
  }

  /**
   * Verifica se existe próximo um elemento na coleção.
   *
   * @returns {boolean}
   */
  hasNext(): boolean {
    return this.iterator + 1 < this.size() - 1;
  }

  /**
   * Verifica se existe um elemento anterior na coleção.
   *
   * @returns {boolean}
   */
  hasPrev(): boolean {
    return this.iterator - 1 > 0;
  }

  /**
   * Procura um elemento na coleção.
   *
   * Retorna o index onde encontrou o elemento na coleção, ou -1 para elementos não encontrados.
   *
   * @param element
   * @returns {number}
   */
  find(element: T): number {
    let i = 0;
    for (const entry of this.storage) {
      if (entry === element) {
        return i;
      }
      i++;
    }

    return -1;
  }

  /**
   * Insere um novo elemento após ao elemento indicado.
   *
   * @param element
   * @param after
   * @returns {boolean}
   */
  insertAfter(element: T, after: T): boolean {
    const insertPos = this.find(after);

    if (insertPos > -1) {
      this.storage.splice(insertPos + 1, 0, element);
      return true;
    }

    return false;
  }

  /**
   * Insere um novo elemento no início da lista
   *
   * @param element
   * @returns {void}
   */
  insertAtBeginning(element: T): void {
    this.storage.unshift(element);
  }

  /**
   * Retorna o tamanho da coleção.
   *
   * @returns {number}
   */
  size(): number {
    return this.storage.length;
  }

  /**
   * Move o ponteiro de iteração para o inicio da coleção.
   *
   * @returns void
   */
  start(): void {
    this.iterator = 0;
  }

  /**
   * Move o ponteiro de iteração para o fim da coleção.
   *
   * @returns void
   */
  end(): void {
    this.iterator = this.size() - 1;
  }

  /**
   * Move o ponteiro de iteração para o elemento anterior na coleção.
   *
   * @returns void
   */
  prev(): void {
    if (this.hasPrev()) {
      --this.iterator;
    }
  }

  /**
   * Move o ponteiro de iteração para próximo elemento na coleção.
   *
   * @returns void
   */
  next(): void {
    if (this.hasNext()) {
      ++this.iterator;
    }
  }

  /**
   * Retorna a posição atual do ponteiro de iteração.
   *
   * @returns {number}
   */
  getIterator(): number {
    return this.iterator;
  }

  /**
   * Move o ponteiro de iteração para a posição indicada.
   *
   * @param {number} index
   */
  moveTo(index: number): void {
    if (index > -1 && index < this.size()) {
      this.iterator = index;
    }
  }

  /**
   * Retorna um elemento da coleção, se o index for informado irá retornar o elemento dessa posição,
   * se nenhum index for informado irá retornar o elemento da posição atual do ponteiro de iteração.
   *
   * @param {number} index
   * @returns {T}
   */
  get(index?: number): T {
    return isNumber(index) ? this.storage[index] : this.storage[this.iterator];
  }

  /**
   * Verifica se o elemento pertence a coleção.
   *
   * @param element
   * @returns {boolean}
   */
  contains(element: T): boolean {
    return this.find(element) > -1;
  }

  /**
   * Verifica se a coleção está vazia.
   *
   * @returns {boolean}
   */
  isEmpty(): boolean {
    return this.size() <= 0;
  }
}
