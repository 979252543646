import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AppConfig } from "@app/app.config";
import { FormularioUnsubscribeUtil } from "@bower-components/astutus-formulario/formulario-unsubscribe.util";
import { SessionStorageService } from "@services/sessionstorage.service";
import { Observable } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { isNullOrUndefined } from "is-what";

/**
 * Controle para adicionar rotas e navegar.
 */
@Injectable()
export class AppRoutingController extends FormularioUnsubscribeUtil {
  /**
   * Indica se uma navegação deverá ser realizada.
   *
   * @type {EventEmitter<any>}
   */
  private readonly shouldNavigate: EventEmitter<{
    pageContent: any;
    opts?: Array<any>;
  }> = new EventEmitter();

  constructor(
    private router: Router,
    private http: HttpClient,
    private sessionService: SessionStorageService
  ) {
    super();
    this.navigationListener();
  }

  /**
   * Verifica pra onde deve navegar, caso não tenha feito cache anteriormente dos dados da tela, baixa e manda pra tela.
   */
  // TODO documentação confusa, necessita melhor detalhamento.
  navigate(formulario) {
    sessionStorage.setItem(formulario.dsUrl, JSON.stringify(formulario));
    this.router.navigate([formulario.dsUrl]);
  }

  /**
   * @deprecated
   * @param url
   */
  goTo(url) {
    this.router.navigate([url]);
  }

  /**
   * Recupera os dados do formulário de cadastro e então emite um evento solicitando
   * a navegação para a rota do formulário.
   *
   * @param path - Chave para recuperar as informações do formulário
   * @param {Array<any>} opts - Dados extras que seão adicionados na url no momento da navegação.
   */
  goToCadastro(path, opts?: any[], type = "pesquisa"): void {
    const pageContent = this.sessionService.getItem(path);

    // Garante que as informações da rota sejam carregadas antes de tentar acessa-la.
    if (isNullOrUndefined(pageContent)) {
      this.getFormulario(path, type).subscribe((formulario: any) => {
        sessionStorage.setItem(
          formulario.data.dsUrl,
          JSON.stringify(formulario.data)
        );

        this.shouldNavigate.emit({ pageContent: formulario.data, opts });
      });
    }

    if (!isNullOrUndefined(pageContent)) {
      this.shouldNavigate.emit({ pageContent, opts });
    }
  }

  /**
   * Recupera os dados do formulário de edição e então emite um evento solicitando
   * a navegação para a rota do formulário.
   *
   * @param path - Chave para recuperar as informações do formulário
   * @param {Array<any>} opts - Dados extras que seão adicionados na url no momento da navegação.
   * @param type - id do objeto a ser editado
   */
  goToUpdate(path, opts?: Array<any>, type = "pesquisa"): void {
    const pageContent = this.sessionService.getItem(path);
    let aux;
    if (isNullOrUndefined(pageContent)) {
      this.getFormulario(path, type).subscribe((formulario: any) => {
        sessionStorage.setItem(
          formulario.data.dsUrl,
          JSON.stringify(formulario.data)
        );
        aux = JSON.parse(JSON.stringify(formulario.data));
        aux.formularioCadastro = null;
        this.shouldNavigate.emit({
          pageContent: aux,
          opts: ["update", ...opts],
        });
      });
    }
    if (!isNullOrUndefined(pageContent)) {
      aux = JSON.parse(JSON.stringify(pageContent));
      aux.formularioCadastro = null;
      this.shouldNavigate.emit({ pageContent: aux, opts: ["update", ...opts] });
    }
  }

  /**
   * Recupera os dados do formulário e emite um evento solicitando
   * a navegação para a rota do formulário.
   *
   * @param path - Chave para recuperar as informações do formulário
   * @param {Array<any>} opts - Dados extras que seão adicionados na url no momento da navegação.
   * @param type - id do objeto a ser editado
   */
  goToDetails(path, opts?: Array<any>, type = "cadastro"): void {
    const pageContent = this.sessionService.getItem(path);
    let aux;
    if (isNullOrUndefined(pageContent)) {
      this.getFormulario(path, type).subscribe((formulario: any) => {
        sessionStorage.setItem(
          formulario.data.dsUrl,
          JSON.stringify(formulario.data)
        );
        aux = JSON.parse(JSON.stringify(formulario.data));
        aux.formularioCadastro = null;
        this.shouldNavigate.emit({ pageContent: aux, opts });
      });
    }
    if (!isNullOrUndefined(pageContent)) {
      aux = JSON.parse(JSON.stringify(pageContent));
      aux.formularioCadastro = null;
      this.shouldNavigate.emit({ pageContent: aux, opts });
    }
  }

  /**
   * Realiza o request pra buscar os dados da tela.
   */
  public getFormulario(dsUrl: string, type: string): Observable<any> {
    const url = `${AppConfig.ASTUTUS_API_URL}/formulario/${type}/consulta?dsUrl=${dsUrl}`;
    return this.http.get(url);
  }

  /**
   * Adiciona um observable para identificar se deve navegar para um outra rota.
   */
  private navigationListener(): void {
    this.shouldNavigate.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
      const { pageContent } = data;
      const { opts } = data;

      const url = pageContent.formularioCadastro
        ? pageContent.formularioCadastro.dsUrl
        : pageContent.dsUrl;
      if (opts) {
        this.router.navigate([url, ...opts]);
      }

      if (!opts) {
        this.router.navigate([url]);
      }
    });
  }

  /**
   * Abre PDF do relatório
   * @param data
   */
  navigeteToReport(data) {
    const nmFilename = data.nmFilename
      .replace("/s3arquivo/download/", "")
      .replace(".pdf", "");
    this.goToCadastro("/app/pdf-viewer/file-name", [nmFilename]);
  }
}
