import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutingController } from '@app/pages/app/app.routing.controller';
import { ArrayList } from '@bower-components/astutus-tabgroup/array-list';
import { TabGroupSession } from '@bower-components/astutus-tabgroup/tab-group-session';
import { TabGroupObservable } from '@bower-components/astutus-tabgroup/tab-group.observable';
import { TabItem } from '@bower-components/astutus-tabgroup/tab-item';
import { ReuseStrategyObservable } from './reuse-strategy-observable';

/**
 * @description Serviço responsável por resetar a rota, mantendo o componente na mesma
 * posição da lista de abas.
 *
 * Este serviço pode ser invocado quando uma rota possuir parâmetros que já foram
 * utilizados pelo componente e não são mais necesários. Permitindo que o componente
 * volte ao seu estado "padrão"
 *
 * @example
 * constructor(private router: Router) { }
 *
 * const currentUrl = this.router.url; // '/app/vendas/example/1/update'
 * const resetUrl = '/app/vendas/example/new';
 *
 * this.componentRouteResetService.exceute(currentUrl, resetUrl);
 *
 * @author Higor Gardin <higor.gardin@nexuscloud.com.br>
 * @since 04/08/2021
 */
@Injectable()
export class ComponentRouteResetService {
  /**
   * Construtor.
   */
  constructor(
    tabGroupObservable: TabGroupObservable,
    public tabLinks: ArrayList<TabItem>,
    private appRoutingController: AppRoutingController,
    private strategyObservable: ReuseStrategyObservable,
    private tabGroupSession: TabGroupSession,
    private router: Router
  ) {}

  /**
   * @param currentUrl
   * @param resetUrl
   */
  execute(currentUrl: string, resetUrl: string): void {
    const currentTab: TabItem = this.tabLinks.toArray().find(tab => tab.dsUrl.includes(currentUrl));
    const currentTabIndex: number = this.tabLinks.find(currentTab);

    const newTab: TabItem = {
      ...currentTab,
      dsUrl: resetUrl,
    };

    this.tabLinks.moveTo(currentTabIndex);
    this.tabLinks.remove(currentTabIndex);

    if (currentTabIndex > 0) {
      const previousTab: TabItem = this.tabLinks.get(currentTabIndex - 1);
      this.tabLinks.insertAfter(newTab, previousTab);
    } else {
      this.tabLinks.insertAtBeginning(newTab);
    }

    this.tabGroupSession.itemChanges.next(this.tabLinks.toArray());
    if (currentTab) {
      this.strategyObservable.notify(currentTab.dsUrl);
    }

    this.router.navigate([resetUrl]);
  }
}
