import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfig } from "@app/app.config";
import { Router } from "@angular/router";

type EncodeUrlParams = {
  client_id: string;
  client_secret: string;
  grant_type: string;
  refresh_token?: string;
  username?: string;
  password?: string;
};

@Injectable()
export class OauthService {
  constructor(private http: HttpClient, private router: Router) {}

  doLogout() {
    AppConfig.OAUTH_DATA = undefined;
    localStorage.clear();
    sessionStorage.clear();
    this.goToLogin();
  }

  goToLogin() {
    this.goToHome().then(() => this.router.navigate(["/login"]));
  }

  goToHome() {
    return this.router.navigate(["/app"]);
  }

  public doLogin(user: string, pwd: string) {
    const headers: HttpHeaders = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );

    const body: string = this.buildFormUrlencodedParams({
      client_id: AppConfig.CLIENT_ID,
      client_secret: AppConfig.CLIENT_SECRET,
      grant_type: "password",
      username: user,
      password: pwd,
    });

    return this.http.post(AppConfig.OAUTH_URL + "/login", body, {
      headers: headers,
    });
  }

  public refreshToken(refreshToken: string) {
    const headers: HttpHeaders = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );

    const body: string = this.buildFormUrlencodedParams({
      client_id: AppConfig.CLIENT_ID,
      client_secret: AppConfig.CLIENT_SECRET,
      grant_type: "refresh_token",
      refresh_token: refreshToken,
    });

    return this.http.post(AppConfig.OAUTH_URL + "/login", body, {
      headers: headers,
    });
  }

  private buildFormUrlencodedParams(data: EncodeUrlParams): string {
    const params = Object.keys(data).reduce(
      (param, key) =>
        param.concat(key.concat("=".concat(data[key].concat("&")))),
      ""
    );

    return params.substring(0, params.length - 1);
  }
}
