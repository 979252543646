import { Injectable } from '@angular/core';
import { AgGridColumnModel } from './ag-grid-column.model';
import { AgGridRenderer } from './ag-grid-renderer';
import { AgGridColumnType } from './ag-grid-column-type';

@Injectable()
export class AgGridColumnHelper {
  constructor(private agGridRenderer: AgGridRenderer) {}

  /**
   * Monta a definição das colunas para o grid
   * @param columns Lista de colunas
   * @return Definição de colunas para AgGrid
   */
  getColumnDefs(columns: AgGridColumnModel[]) {
    return columns.map(column => this.getGridColumn(column));
  }

  /**
   * Monta uma coluna de acordo com as definições
   * @param column
   */
  getGridColumn(column: AgGridColumnModel): any {
    return {
      headerName: column.headerName,
      field: column.field,
      cellClass: `col-grid-${column.size}`,
      headerClass: `col-grid-${column.size} ${column.isFixed ? 'pinned-cell' : ''} ${
        column.sortable ? 'sortable' : ''
      }`,
      lockPosition: column.isFixed,
      pinned: column.isFixed ? 'left' : '',
      maxWidth: column.maxWidth,
      valueFormatter: params => this.formatValue(column, params),
      cellStyle: column.cellStyle,
    };
  }

  /**
   * Formata o valor da coluna de verificando a seguinte hierarquia:
   * 1) valueFormatter: Formata de acordo com a função recebida.
   * 2) type. Formata de acordo com o tipo da coluna.
   * 3) Caso os dois parâmetros anteriores sejam nulos, retorna o valor sem formatação.a
   *
   * @param column Definições da coluna
   * @param params Parâmetros fornecidos pela célula do ag-grid.
   */
  formatValue(column: AgGridColumnModel, params: any) {
    if (column.valueFormatter) {
      return column.valueFormatter(params);
    }
    switch (column.type) {
      case AgGridColumnType.BOOLEAN:
        return this.agGridRenderer.boolean(params);
      case AgGridColumnType.DATE:
        return this.agGridRenderer.date(params);
      case AgGridColumnType.DECIMAL:
        return this.agGridRenderer.decimal(params);
      case AgGridColumnType.DATE_TIME:
        return this.agGridRenderer.dateHour(params);
      case AgGridColumnType.ENUM:
        return this.formatEnum(column, params);
      case AgGridColumnType.CURRENCY:
        return this.agGridRenderer.currency(params);
      default:
        return params.value;
    }
  }

  /**
   * Formata um valor de acordo com a lista de parâmetros do enum
   * @param column Definições da coluna
   * @param params Parâmetros fornecidos pela célula do ag-grid.
   */
  private formatEnum(column: AgGridColumnModel, params: any) {
    if (!column.enum) {
      return params.value;
    }
    const item = column.enum.find(item => item.value === params.value);
    return item ? item.label : params.value;
  }
}
