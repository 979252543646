/**
 * reuse-strategy.ts
 * by corbfon 1/6/17
 */
import { ActivatedRouteSnapshot, RouteReuseStrategy, DetachedRouteHandle } from '@angular/router';
import { Injectable } from '@angular/core';
import { ReuseStrategyObservable } from './reuse-strategy-observable';

/** Interface for object which can store both:
 * An ActivatedRouteSnapshot, which is useful for determining whether or not you should attach a route (see this.shouldAttach)
 * A DetachedRouteHandle, which is offered up by this.retrieve, in the case that you do want to attach the stored route
 */
interface RouteStorageObject {
  // snapshot: ActivatedRouteSnapshot;
  handle: DetachedRouteHandle;
}

/**
 * @whatItDoes Provides a customized way to retrieve stored routes.
 *
 * @description This class and almost an all explanation was provided by carbon in StackOverflow
 *
 * @see {https://stackoverflow.com/questions/41280471/how-to-implement-routereusestrategy-shoulddetach-for-specific-routes-in-angular}
 *
 *
 * @experimental
 */
@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {
  /**
   * Object which will store RouteStorageObjects indexed by keys
   * The keys will all be a path (as in route.routeConfig.path)
   * This allows us to see if we've got a route stored for the requested path
   */
  storedRoutes: { [key: string]: RouteStorageObject } = {};

  /**
   * A collections routes that should be replaced.
   *
   * @type {Array}
   */
  shouldReplace: any[] = [];

  constructor(strategyObservable: ReuseStrategyObservable) {
    strategyObservable.getObservable().subscribe(path => {
      this.shouldReplace[this.shouldReplace.length] = path;

      this.destroyRouteComponent(path);

      delete this.storedRoutes[path];
    });
  }

  /**
   * @description Função utilizada para destruir o componente quando for fechada a tab.
   * Previne que o componente ainda mantenha o estado mesmo quando a tab é fechada.
   *
   * @param path
   */
  private destroyRouteComponent(path: any) {
    const component: any = this.storedRoutes[path];

    if (component && component.handle && component.handle.componentRef) {
      component.handle.componentRef.destroy();
    }
  }

  /**
   * Decides when the route should be stored
   * If the route should be stored, I believe the boolean is indicating to a controller whether or not to fire this.store
   * _When_ it is called though does not particularly matter, just know that this determines whether or not we store the route
   * An idea of what to do here: check the route.routeConfig.path to see if it is a path you would like to store
   * @param route This is, at least as I understand it, the route that the user is currently on,
   * and we would like to know if we want to store it
   * @returns boolean indicating that we want to (true) or do not want to (false) store that route
   */
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    if (
      this.shouldReplace.includes((route as any)._routerState.url) ||
      route.routeConfig.loadChildren
    ) {
      return false;
    }

    return true;
  }

  /**
   * Constructs object of type `RouteStorageObject` to store, and then stores it for later attachment
   * @param route This is stored for later comparison to requested routes, see `this.shouldAttach`
   * @param handle Later to be retrieved by this.retrieve, and offered up to whatever controller is using this class
   */
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.storedRoutes[(route as any)._routerState.url] = {
      handle: { ...handle },
    };
  }

  /**
   * Determines whether or not there is a stored route and, if there is, whether or not it should be rendered in place of requested route
   * @param route The route the user requested
   * @returns boolean indicating whether or not to render the stored route
   */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    for (let i = 0; i < this.shouldReplace.length; i++) {
      if (this.shouldReplace[i] === (route as any)._routerState.url) {
        this.shouldReplace.splice(i, 1);
        return false;
      }
    }

    // this will be true if the route has been stored before
    return !!route.routeConfig && !!this.storedRoutes[(route as any)._routerState.url];
  }

  /**
   * Finds the locally stored instance of the requested route, if it exists, and returns it
   * @param route New route the user has requested
   * @returns DetachedRouteHandle object which can be used to render the component
   */
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    // return null if the path does not have a routerConfig OR if there is no stored route for that routerConfig
    if (!route.routeConfig || !this.storedRoutes[(route as any)._routerState.url]) {
      return null;
    }

    if (route.routeConfig.loadChildren) return null;

    /** returns handle when the route.routeConfig.path is already stored */
    const requestedPath = (route as any)._routerState.url;
    return this.storedRoutes[requestedPath].handle;
  }

  /**
   * Determines whether or not the current route should be reused
   * @param future The route the user is going to, as triggered by the router
   * @param curr The route the user is currently on
   * @returns boolean basically indicating true if the user intends to leave the current route
   */
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}
