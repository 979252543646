import { Component, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
  selector: "app-checkbox-cell",
  template: `
    <mat-checkbox [formControl]="checked" color="accent"></mat-checkbox>
  `,
  styles: [
    `
      ::ng-deep .mat-checkbox-layout {
        /* horizontally align the checkbox */
        width: 100%;
        display: inline-block !important;
        text-align: center;
        margin-top: -4px; /* to offset the cells internal padding - could be done in cells CSS instead*/

        /* vertically align the checkbox when not using the ag-material theme - should be the same as the
                rowHeight - cell padding
                   (you can of course alter the cell padding via CSS instead if you'd prefer)
                line-height: 42px;
                 */
      }
    `,
  ],
})
export class SingleCheckboxComponent
  implements ICellRendererAngularComp, OnInit
{
  params: any;

  checked: UntypedFormControl;

  agInit(params: any): void {
    this.params = params;
  }

  // demonstrates how you can do "inline" editing of a cell
  onChange(checked: boolean) {
    this.params.value = checked;

    if (this.params && this.params.onChange) {
      this.params.onChange(this.params.value);
    }
  }

  ngOnInit() {
    this.checked = new UntypedFormControl(this.params.value === true);

    this.checked.valueChanges.subscribe((data) => this.onChange(data));
  }

  refresh(params: any): boolean {
    return false;
  }

  constructor() {}
}
