import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

import { isNull } from 'is-what';

import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable()
export class DateConverterInterceptor implements HttpInterceptor {
  readonly pattern = /^dt|^dh/i;

  constructor() {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.method === 'GET') {
      return next.handle(req).pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            this.timestampToDate((event.body || {}).data);
          }
        })
      );
    }

    return next.handle(req);
  }

  private timestampToDate(data: any): void {
    if (!isNull(data) && data !== undefined) {
      Object.keys(data).forEach(key => {
        const condition =
          (typeof data[key] === 'object' && !isNull(data[key])) ||
          Array.isArray(data[key] && data[key].length);

        if (condition) {
          this.timestampToDate(data[key]);
        }

        // verifica se a chave pertence a um objeto do tipo data
        const result = this.pattern.test(key);

        if (result === true && data[key]) {
          data[key] = new Date(data[key]).toISOString();
        }
      });
    }
  }
}
