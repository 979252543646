import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

/**
 * @whatItDoes Apresenta uma modal com uma mensagem solicitando confirmação da ação.
 *
 * @example
 *
 * const idItem: number = item.idItem;
 *
 * const dialogRef = this.dialog.open(RemoveDialogComponent, {
 *   width: '480px',
 *   data: {
 *    'message': `Confirma a exclusão do item de código ${idContagem} ?`
 *   }
 * });
 *
 * dialogRef.afterClosed().subscribe(result => {
 *   if (result) {
 *     // implementação
 *   }
 * });
 *
 * @class RemoveDialogComponent
 */
@Component({
  selector: 'app-remove-dialog',
  templateUrl: './remove-dialog.component.html',
  styleUrls: ['./remove-dialog.component.scss']
})
export class RemoveDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

}
