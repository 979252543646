import { Directive, HostListener, Renderer2, ElementRef } from '@angular/core';
import { AbstractFormatter } from '@shared/directives/abstract-formatter';

@Directive({
  selector: '[dateMask]'
})
export class DateMaskDirective extends AbstractFormatter {

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    super();
  }

  @HostListener('input', ['$event'])
  onInput($event: any) {
    const textNumber = this.clearValue($event.target.value);
    const masked = this.formatValue(textNumber, '99/99/9999');
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', masked);
  }

}
