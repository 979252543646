import { AppConfig } from "./../../app.config";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable()
export class PerfilamentoService {
  private baseUrl: string = AppConfig.OAUTH_URL + "/perfil";

  get oauthHeader() {
    return new HttpHeaders({
      Authorization: "Bearer " + AppConfig.OAUTH_DATA.access_token,
      "Content-Type": "application/x-www-form-urlencoded",
    });
  }

  constructor(private http: HttpClient) {}

  public getPerfilamento(): any {
    return this.http.get(
      this.baseUrl + "/" + AppConfig.OAUTH_DATA.access_token,
      {
        headers: this.oauthHeader,
      }
    );
  }
}
