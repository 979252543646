import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RadioButtonService } from "./radio-button-service";

@Component({
  selector: "app-radio-cell",
  template: `
    <mat-radio-group
      [name]="params?.data?.groupName"
      [value]="selected"
      [disabled]="disabled"
      (change)="onChange($event)"
    >
      <mat-radio-button [value]="params?.value"></mat-radio-button>
    </mat-radio-group>
  `,
  styles: [
    `
      ::ng-deep.mat-radio-disabled .mat-radio-outer-circle {
        border-color: rgba(0, 0, 0, 0.38) !important;
      }

      ::ng-deep.mat-radio-disabled .mat-radio-inner-circle {
        background-color: rgba(0, 0, 0, 0.38) !important;
      }
    `,
  ],
})
export class RadioButtonComponent implements ICellRendererAngularComp {
  params: any;

  selected = 0;

  disabled = false;

  unsubscribe: Subject<void> = new Subject();

  agInit(params: any): void {
    this.params = params;
  }

  onChange($event) {
    this.params.value = $event.value;
    this.selected = this.params.value;
    this.params.node.setDataValue(this.params.colDef, this.selected);
    this.radioService.changeRadioOption(this.params);
  }

  constructor(private radioService: RadioButtonService) {
    radioService.setChange$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((selectedRadio) => {
        if (!this.disabled) {
          this.selected = selectedRadio;
        }
      });

    radioService.setDisableGroup$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((groupName) => {
        if (this.params.data && this.params.data.groupName === groupName) {
          this.disabled = true;
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  refresh(params: any): boolean {
    return false;
  }
}
