export enum AgGridColumnType {
  /**
   * Boolean. Sim/Não
   */
  BOOLEAN,
  /**
   * Texto. Nao aplica formatação
   */
  TEXT,
  /**
   * Números. Formata com 2 casas decimais no padrão pt-BR.
   */
  DECIMAL,
  /**
   * Data. Formata no padrão dd/MM/yyyy
   */
  DATE,
  /**
   * Data e hora. Formata no padrão dd/MM/yyyy HH:mm:ss
   */
  DATE_TIME,
  /**
   * Lista. Formata de acordo com a lista de código e valores
   */
  ENUM,
  /**
   * Números. Aplica formatação monetária
   */
  CURRENCY,
}
