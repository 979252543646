import { Directive, HostListener, ElementRef, Renderer2 } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Directive({
  selector: "[appTrim]",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TrimDirective,
      multi: true,
    },
  ],
})
export class TrimDirective implements ControlValueAccessor {
  onTouched: any;

  onChange: any;

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      "value",
      `${value || ""}`.trim()
    );
  }

  @HostListener("input", ["$event"])
  onInput(_event: any) {
    this.onChange(_event.target.value.trim());
  }

  @HostListener("blur", ["$event"])
  onBlur(_event: any) {
    Object.assign(_event.target, {
      ..._event.target,
      value: _event.target.value.trim(),
    });
    this.onTouched();
  }
}
