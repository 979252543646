export const environment = {
  production: false,
  isProduction: true,
  TP_MENU: 0,
  OAUTH_URL: "https://oauth.api.nexuscloud.com.br/api",
  API_URL_CADASTRO: "https://cadastro.api.nexuscloud.com.br/api",
  API_URL_COMPRAS: "https://compras.api.nexuscloud.com.br/api",
  API_URL_ESTOQUE: "https://estoque.api.nexuscloud.com.br/api",
  API_URL_FINANCEIRO: "https://financeiro.api.nexuscloud.com.br/api",
  API_URL_VENDAS: "https://vendas.api.nexuscloud.com.br/api",
  API_URL_PRECOS: "https://precos.api.nexuscloud.com.br/api",
  API_URL_FISCAL: "https://fiscal.api.nexuscloud.com.br/api",
  API_URL_COMISSOES: "https://comissoes.api.nexuscloud.com.br/api",
  ASTUTUS_API_URL: "https://astutus.api.nexuscloud.com.br/api",
  S3_API_URL: "https://s3.api.nexuscloud.com.br/api",
  API_URL_REPORT: "https://report.api.nexuscloud.com.br/api",
  API_URL_PRESTO: "https://presto.api.nexuscloud.com.br/api",
  API_URL_SYNC: "https://sync.api.nexuscloud.com.br/api",
  API_URL_MONITOR_NFE: "https://monitor-nfe.api.nexuscloud.com.br/api",
  API_URL_DOMINIO: "https://dominio.api.nexuscloud.com.br/api",
  INTEGRACAO_DOMINIO_URL: "https://app.nexuscloud.com.br/#/integracao/dominio",
};
