import { Directive, ElementRef } from "@angular/core";
import { AgGridAngular } from "ag-grid-angular";
import { ColumnMovedEvent } from "ag-grid-community";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";

type GridConfigMap = {
  [gridId: string]: GridConfig;
};

type GridConfig = {
  columnIndex: {
    [columnId: string]: number;
  };
};

/**
 * @description Controla o redimensionamento das colunas de maneira automática.
 * Armazena e restaura a posição das colunas definidas pelo usuário.
 *
 * @author Valdecir Correa <valdecir.correa@nexuscloud.com.br>
 */
@Directive({
  selector: "ag-grid-angular[agGridResizeApi]",
})
export class AgGridResizeApiDirective {
  private gridConfigKey = "grid_config";

  private requestSetColumnsMinWidth = false;

  constructor(
    private agGridComponent: AgGridAngular,
    private agGridElement: ElementRef<HTMLElement>
  ) {
    agGridComponent.gridReady.subscribe(this.onGridReady.bind(this));
  }

  private onGridReady(): void {
    this.agGridComponent.rowDataUpdated.subscribe(() => {
      this.requestSetColumnsMinWidth = true;
      this.sizeColumnsToFit();
    });

    this.agGridComponent.gridSizeChanged
      .pipe(
        map((event) => event.clientWidth),
        distinctUntilChanged(),
        debounceTime(50)
      )
      .subscribe(this.sizeColumnsToFit.bind(this));

    this.positionColumnsAsConfig();
    setTimeout(() => {
      this.setColumnsMinWidth();
      this.agGridComponent.columnMoved.subscribe(
        this.storeColumnPosition.bind(this)
      );
    });
  }

  private sizeColumnsToFit(): void {
    if (!this.isGridVisible()) return;

    if (this.requestSetColumnsMinWidth) {
      this.setColumnsMinWidth();
      this.requestSetColumnsMinWidth = false;
    }

    this.agGridComponent.api.sizeColumnsToFit({
      columnLimits: this.agGridComponent.api.getColumns().map((column) => {
        return {
          key: column.getColId(),
          minWidth: column.getColDef().minWidth,
        };
      }),
    });
  }

  private setColumnsMinWidth(): void {
    this.agGridComponent.api.autoSizeAllColumns();
    this.agGridComponent.api.getColumns().forEach((column) => {
      column.getColDef().minWidth = column.getActualWidth();
    });
  }

  private positionColumnsAsConfig(): void {
    const gridId = this.getGridId();
    const gridConfig = this.getGridConfig(gridId);

    Object.entries(gridConfig.columnIndex)
      .sort((a, b) => a[1] - b[1])
      .forEach(([columnId, index]) => {
        this.agGridComponent.api.moveColumn(columnId, index);
      });
  }

  private storeColumnPosition(event: ColumnMovedEvent): void {
    if (!event.column) return;

    const gridId = this.getGridId();
    const columnId = event.column.getId();
    const columState = event.api.getColumnState();
    const gridConfig = this.getGridConfig(gridId);
    gridConfig.columnIndex[columnId] = event.toIndex;

    Object.keys(gridConfig.columnIndex).forEach((colId) => {
      gridConfig.columnIndex[colId] = columState.findIndex(
        (state) => state.colId === colId
      );
    });

    this.storeConfig(gridId, gridConfig);
  }

  private getGridConfig(gridId: string): GridConfig {
    const configMap = JSON.parse(
      localStorage.getItem(this.gridConfigKey)
    ) as GridConfigMap;
    return configMap?.[gridId] ?? { columnIndex: {} };
  }

  private storeConfig(gridId: string, config: GridConfig): void {
    const configMap = (JSON.parse(localStorage.getItem(this.gridConfigKey)) ||
      {}) as GridConfigMap;
    configMap[gridId] = config;
    localStorage.setItem(this.gridConfigKey, JSON.stringify(configMap));
  }

  private getGridId(): string {
    const columnsId = this.agGridComponent.api
      .getColumns()
      .map((col) => col.getColId())
      .join("");
    return `${this.agGridElement.nativeElement.id}_${btoa(columnsId)}`;
  }

  private isGridVisible(): boolean {
    return this.agGridElement.nativeElement.clientWidth > 0;
  }
}
