import { Component, OnInit } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
  selector: "app-checkbox-cell",
  template: `
    <mat-checkbox [formControl]="checked" color="accent"></mat-checkbox>
  `,
  styles: [
    `
      ::ng-deep .mat-checkbox-layout {
        /* horizontally align the checkbox */
        width: 100%;
        display: inline-block !important;
        text-align: center;
        margin-top: -4px; /* to offset the cells internal padding - could be done in cells CSS instead*/

        /* vertically align the checkbox when not using the ag-material theme - should be the same as the
                rowHeight - cell padding
                   (you can of course alter the cell padding via CSS instead if you'd prefer)
                line-height: 42px;
                 */
      }
    `,
  ],
})
export class CheckboxComponent implements ICellRendererAngularComp, OnInit {
  params: any;

  checked: UntypedFormControl;

  disabled = false;

  agInit(params: any): void {
    this.params = params;
    this.disabled = this.params.data ? this.params.data.disabled : false;
  }

  ngOnInit() {
    this.checked = new UntypedFormControl({
      value: this.params.value === true,
      disabled: this.disabled,
    });

    this.checked.valueChanges.subscribe((data) => this.onChange(data));
  }

  onChange($event) {
    this.params.value = $event;
    this.params.node.setDataValue(this.params.colDef, this.checked.value);
    if (this.params && this.params.onChange) {
      this.params.onChange(this.params);
    }
  }

  constructor() {}

  refresh(params: any): boolean {
    return false;
  }
}
