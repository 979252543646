import { GridOptions } from "ag-grid-community";

/**
 * Configura os aspectos da table do AgGrid, informando as opções mais utilizadas.
 *
 * @type GridOptions
 */
export const AgGridOptions: GridOptions = {
  rowHeight: 48,
  headerHeight: 56,
  rowSelection: "single",
  suppressClickEdit: true,
  suppressMultiSort: true,
  suppressColumnVirtualisation: true,
  suppressDragLeaveHidesColumns: true,
  suppressNoRowsOverlay: true,
  suppressRowDrag: true,
  localeText: { noRowsToShow: " " },
  defaultColDef: {
    resizable: false,
  },
  defaultColGroupDef: {
    marryChildren: true,
  },
};
