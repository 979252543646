import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { TabItem } from './tab-item';
/**
 * @whatItDoes Armazena as informações de cada tela adicionada no tab-group em uma sessão.
 *
 * @description
 * A classe a seguir fornece um evento para gerenciar as telas
 * armazenas em sessão para serem reabertas no tab-group.
 *
 * Ao adicionar várias telas no tab-group elas são salvas. Desta forma
 * ao ser atualizado, o sistema remonta os tabs que estavam abertos antes.
 *
 * @experimental
 */
@Injectable()
export class TabGroupSession {
  /**
   * Informa que houve mudança nos itens do tab-group. As ações de abrir
   * uma nova tela ou fechar uma disparam essas mudanças, sendo elas
   * adicionar ou remover um item do tab-group.
   */
  readonly itemChanges: BehaviorSubject<TabItem[]>;

  /**
   * Chave utilizada para gerenciar a sessão.
   *
   * @type {string}
   */
  private keySession = 'tabGroupSession';

  /**
   * Coleção de itens adicionados no tab-group.
   */
  private _storage: TabItem[];

  get storage(): TabItem[] {
    return this._storage;
  }

  constructor() {
    this.itemChanges = new BehaviorSubject<TabItem[]>(
      JSON.parse(sessionStorage.getItem(this.keySession))
    );
    this.itemChanges.subscribe(dataStorage => this.setStorage(dataStorage));
  }

  /**
   * Espelha as modificações ocorridas no itens do tab-group.
   * Se nenhum item existir no tab-group a sessão é deletada.
   *
   * @param {TabItem[]} dataStorage
   */
  private setStorage(dataStorage: TabItem[]): void {
    this._storage = dataStorage || [];

    if (this.storage.length > 0) {
      sessionStorage.setItem(this.keySession, JSON.stringify(this._storage));
    } else {
      sessionStorage.removeItem(this.keySession);
    }
  }
}
