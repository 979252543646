import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[select-on-focus]',
})
export class SelectOnFocusDirective {
  @HostListener('focus', ['$event']) selectOnFocus($event) {
    $event.target.select();
  }
}
